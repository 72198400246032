::-webkit-input-placeholder {
  /* Edge */
  color: #595959;
  font-weight: 400;
  font-size: 20px;
  font-family: "Cabinet Grotesk", sans-serif;
  line-height: 55px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #595959;
  font-weight: 400;
  font-size: 20px;
  font-family: "Cabinet Grotesk", sans-serif;
  line-height: 25px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

::placeholder {
  color: #595959;
  font-weight: 400;
  font-size: 20px;
  font-family: "Cabinet Grotesk", sans-serif;
  line-height: 25px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
body {
  margin: 0;
  font-family: "Cabinet Grotesk", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box
}
a {
  text-decoration: none;
}
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
